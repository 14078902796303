

html {
  height: 100%;
}

body {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-weight: 400;
  margin: 0;
  padding: 0;
  background: #d9dee1;
}

a {
  text-decoration: none;
  color: #9e5f27;
}

button {
  cursor: pointer;
}

img {
  max-width: 100%;
}



.column {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
}

.wrap {
  flex-wrap: wrap;
}

.start {
  justify-content: start;
}

.end {
  justify-content: end;
}

.center {
  justify-content: center;
}

.space-between {
  align-content: stretch;
  justify-content: space-between;
}

.space-around {
  align-content: stretch;
  justify-content: space-around;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: start;
}

.align-end {
  align-items: end;
}

.align-stretch {
  align-items: stretch;
}

.fill {
  width: 100%;
  flex-grow: 1;
}

.flex {
  flex: 1;
}

.mat-content.mat-content-hide-toggle {
  margin: 0 !important;
}
