
/* You can add global styles to this file, and also import other style files */

@use '@angular/material' as mat;

@include mat.core();

$light-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette(mat.$grey-palette, 100),
    // Create accent palette from scratch, with color DF9A57
    accent: mat.define-palette((
      50: #fdf2e6,
      100: #fbe6cc,
      200: #f9d9b2,
      300: #f6cd97,
      400: #f4c17d,
      500: #df9a57,
      600: #d8914f,
      700: #d08745,
      800: #c87e3c,
      900: #bc6f2e,
      A100: #ffcd8e,
      A200: #ffb363,
      A400: #ff9a39,
      A700: #ff902c,
      contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #000000,
        500: #000000,
        600: #000000,
        700: #000000,
        800: #000000,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
      ),
    ), 500),
  ),
  // Only include `typography` and `density` in the default light theme.
  typography: mat.define-typography-config(),
  density: 0
));

@include mat.all-component-themes($light-theme);

a {
  color: #df9a57;
}

.mat-app-background {
  background-color: #222 !important;
}

.mat-drawer-container {
  background-color: #222 !important;
  overflow: visible !important;
}

.mat-drawer-content {
  // For the gradient effect on the game's background
  overflow: visible !important;
}

.mat-toolbar {
  background-color: #222 !important;
}

.mat-stepper-vertical {
  background-color: #222 !important;
}
.mat-stepper-horizontal {
  background-color: #222 !important;
}
.mat-step-label {
  min-width: 0 !important;
}

.mat-mdc-dialog-container {
  background-color: #222 !important;
  --mdc-dialog-container-color: #222 !important;
}

.mat-mdc-select-panel {
  background-color: #222 !important;
}

.mat-mdc-autocomplete-panel {
  background-color: #222 !important;
}

.mat-mdc-menu-panel {
  background-color: #333 !important;
}

.mat-drawer {
  background-color: #222 !important;
}

.mat-drawer-side {
    border-right: none !important; //solid 1px rgba(255, 255, 255, 0.06);
    border-left: none !important; //solid 1px rgba(255, 255, 255, 0.06);
}

.mat-expansion-panel {
  background-color: #eee !important;
}

.mat-expansion-panel-header {
  padding: 0 16px !important;
  background-color: #eee !important;
}

.mat-form-field {
  background-color: #333 !important;
}

.mat-mdc-text-field-wrapper {
  background-color: #333 !important;
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(28, 41, 53, 0.75) !important;
  // Whenever the drawer overlay covers the full height, use this instead
  //background-color: rgba(0, 0, 0, 0.4) !important;
}

.mat-datepicker-content-container {
  background-color: #333 !important;
}

.mat-mdc-table {
  background-color: #333 !important;
}

.mat-mdc-card {
  background-color: #333 !important;
}

.mat-button-toggle {
  background-color: #222 !important;
  color: #b1b8be;
}

.mat-button-toggle-checked {
  border: 1px solid #466079 !important;
  background-color: #333 !important;
  color: #ffffff;

}

.mat-mdc-menu-panel {
    .mat-button-toggle {
        background-color: #333 !important;
    }
    .mat-button-toggle-checked {
        background-color: #334e69 !important;
    }
}

.mat-mdc-standard-chip {
    background-color: #374d62 !important;
}

.mat-mdc-standard-chip .mdc-evolution-chip__cell--primary, .mat-mdc-standard-chip .mdc-evolution-chip__action--primary, .mat-mdc-standard-chip .mat-mdc-chip-action-label {
    overflow: hidden !important;
}

